<template>
  <div restricted-email>
    <h2>{{ $t('_.title') }}</h2>
    <FontIcon name="ban"/>
    <p class="msg" v-html="$t('_.description')" />
    <div class="email-wrapper">
      <a class="email" :href="mailTo" target="_blank">{{ csEmail }}</a>
    </div>
    <ProgressButton button-name="close" @click="close"/>
  </div>
</template>

<script>
import { close } from '@/utils/clientUtil';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'RestrictedEmail',
  components: { ProgressButton, FontIcon },
  lexicon: 'signUp.restrictedEmail',
  computed: {
    csEmail: state('env', 'csEmail'),
    mailTo() {
      return `mailto:${this.csEmail}`;
    },
  },
  methods: {
    close() {
      close(this);
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[restricted-email] { .tc(); .mt(100);
  h2 { .c(@c-red); .medium(); .fs(36); .mb(56);
    &+[font-icon] { .fs(56); .block(); .mh-c(); .c(@c-red); }
  }
  .msg { .fs(16,22); .c(#9e9e9e); .m(36,0,20); }
  .email-wrapper{.m(36,0,80);
    .email{ .c(@c-link); }
  }

}
</style>